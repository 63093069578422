import {
  BrowserRouter,
  NavLink,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import { Portfolio } from './Portfolio'
import { Team } from './Team'
const routeVariants = {
  initial: {
    y: "100vh",
  },
  final: {
    y: "0vh",
    transition: {
      type: "spring",
      mass: 0.4,
    },
  },
};

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
};

function App() {
  return (
    <BrowserRouter>
      <Header />
      <LocationProvider>
        <RoutesWithAnimation />
      </LocationProvider>
    </BrowserRouter>
  );
}

function LocationProvider({ children }) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function RoutesWithAnimation() {
  const location = useLocation();
  console.log(location);

  return (
    <Routes location={location} key={location.key}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/team" element={<Team />} />

    </Routes>
  );
}

function Header() {
  return (
    <div className="header">
      <span>awkns.</span>
      <ul>
        <NavLink to="/">Home</NavLink>
        {/* <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink> */}
        <NavLink to="/portfolio">Portfolio</NavLink>
        <NavLink to="/contact">Contact</NavLink>

        {/* <NavLink to="/team">Team</NavLink> */}

      </ul>
    </div>
  );
}

function Home() {
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="component"
    >
      <motion.h4 style={{ color: 'white', padding: '30px' }} variants={childVariants} initial="initial" animate="final">
        Research And Investment Lab Focusing On Blockchain, AI, And Entertainment Industries
      </motion.h4>
    </motion.div>
  );
}

function About() {
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      exit="exit"
      className="about component"
    >
      <motion.h1 variants={childVariants} initial="initial" animate="final">
        About Component
      </motion.h1>
    </motion.div>
  );
}

function Contact() {
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      exit="exit"
      className="component"
    >
      <motion.h4 style={{ color: 'white', padding: '30px' }} variants={childVariants} initial="initial" animate="final">
        gm@awkns.com
      </motion.h4>
    </motion.div>
  );
}
export default App;
